/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React, { useState } from "react"
import { TransitionContext } from "./transition-context"
import { navigate } from "gatsby"
import { PageChangeAnimate } from "./src/components/animation/PageChangeAnimate"

const GravitateApp = ({ element }) => {
  const [transitioning, setTransitioning] = useState(false)

  const toggleTransition = path => {
    if (!transitioning) {
      setTransitioning(true)
      path && setTimeout(() => navigate(path), 500)
      setTimeout(() => setTransitioning(false), 1000)
    }
  }
  return (
    <TransitionContext.Provider
      value={{
        transitioning,
        toggleTransition,
      }}
    >
      <PageChangeAnimate />
      {element}
    </TransitionContext.Provider>
  )
}
const Wrapper = ({ element }) => {
  return <GravitateApp element={element} />
}
export { Wrapper as wrapRootElement }
